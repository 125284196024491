import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';


export default class Loader extends Component {
  render() {
  return (
    <div className="loader-outer-wrapper">
      <Fade duration={1200} >
        <div className="loader-wrapper">

          <div className="loading-contents">
            <div className="flipper">
              <div className="front">
                <div>
                  R
                </div>
              </div>
              <div className="back">
                <div>
                  R
                </div>
              </div>
            </div>
            <div className="shadow"></div>
            </div>

          </div>


      </Fade>
    </div>
    )
  }
}

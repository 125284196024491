import React, { Component } from 'react';
import Axios from "axios";
import Loader from '../components/loader';
import Fade from 'react-reveal/Fade';





export default class Video extends Component {
  constructor() {
    super();
    this.state = {
      project: [],
      authorship: [],
      playing: "true",
      video: "",
      muted: false,
      slug: ""
    }
    this.playVideo = this.playVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.muteAll = this.muteAll.bind(this);
    this.updateVideo = this.updateVideo.bind(this);


  }
  componentDidMount() {
    this.setState({
      slug: this.props.slug
    });
  }

 componentDidUpdate(){
   let slug = this.state.slug
   if (slug){
     this.updateVideo();
   }
   window.addEventListener('load', this.playVideo);
 }

 updateVideo(){
   Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/paradoxal?slug=" + this.state.slug).then(post => {
    this.setState({
      project: post.data,
      muted: false
    });
  });
 }

 playVideo() {
     var video = this.refs.video
     video.play();
 }
 closeVideo () {
     document.body.classList.remove('video-view');
     this.setState({
       muted: true,
       displayInfo: "",
       controlsHidden: ""
     });
 }
 muteAll () {
   this.setState(prevState => ({
     muted: !prevState.muted
   }));
 }

  render() {
      let project = this.state.project[0]

      if (!project) {
        return <div className="no-video video-player-wrapper"><Loader /></div>
      }

      const index = this.props.index;

      let video = project && project.acf ? project.acf.video : null
      let director = project && project.acf ? project.acf.director : null
      let title = project && project.title ? project.title.rendered : null
      let category = project && project._embedded && project._embedded['wp:term'] && project._embedded['wp:term']['0'] && project._embedded['wp:term']['0']['0'] ? project._embedded['wp:term']['0']['0'].name : null
      let client = project && project.acf ? project.acf.client : null
      let studio = project && project.acf ? project.acf.studio : null
      let agency = project && project.acf ? project.acf.agency : null
      let location = project && project.acf ? project.acf.location : null

      return (


        <div className="video-wrapper video-player-wrapper">
          <Fade>
            <video className="project-video" loop="loop" muted={this.state.muted} autoPlay={true} data-autoplay={true} data-keepplaying ref="video" onClick={this.closeVideo}>
              <source src={video} type="video/mp4" />
            </video>
          </Fade>

        <div className={'project-1 video-meta-bottom'} ref={index}>
          <div className="content">
            <div className="row">
              <div className="col-6">
                <div className="video-meta-bottom-inner-wrapper">
                  <div className="video-info">
                    <h2 className="outline-white" onClick={this.toggleVideo}>{title}</h2>
                    <div className="more-info">
                      <h3>{director}</h3>
                      <span className="category">{category}</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress-bar-inner"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'video-meta-top content meta-' + index + ' ' + this.state.displayInfo + ' ' + this.state.controlsHidden}>
          <div className="row">
            <div className="col-6 flex">
              <div className="meta-inner-wrapper">
                <div className="meta-single">
                  <h6>Director: <span>{director}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Production Studio: <span>{studio}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Client: <span>{client}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Agency: <span>{agency}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Location: <span>{location}</span></h6>
                </div>
              </div>
            </div>
          </div>
        </div>

</div>

    )
    }
}

import React, { Component } from 'react';
import Axios from "axios";
import { Nav } from 'react-bootstrap'
import { BrowserRouter as Link, NavLink } from 'react-router-dom'
import Fade from 'react-reveal/Fade';




export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
    };
  }

  componentDidMount() {
    Axios.get('http://admin.residencycontent.com/index.php/wp-json/acf/v3/options/paradoxal_settings').then(res => {
      this.setState({content: res.data});
    });
  }

  render() {

  let content = this.state.content;
  let tagline = content && content.acf ? content.acf.tagline : null
  let contactEmail = content && content.acf ? content.acf.contact_email : null
  let address = content && content.acf ? content.acf.address : null
  let phone = content && content.acf ? content.acf.phone_number : null


  return (
    <div className="footer section fp-auto-height">
      <Fade>
      <div className="content">
        <div className="row">

          <div className="col-6">

            <div className="footer-content">
              <h4>{tagline}</h4>
              <a className="button" href={'mailto:' + contactEmail}>
                <div className="button-inner-wrapper">
                  get in touch
                </div>
              </a>
            </div>

            <div className="company-information">
              <span className="address" dangerouslySetInnerHTML={{__html: address}}>

              </span>
              <span className="address">
                {phone}
              </span>
            </div>

          </div>

        </div>
      </div>

      <div className="logos">
        <a href="http://residencycontent.com/" target="_blank">
        <div className="residency"><img src="./logos/r-logo.png" alt="Residency" /></div>
        </a>
        <Nav.Link
          key="/"
          as={NavLink}
          to="/"
          activeClassName="active"
          exact
        >
        <div className="paradoxal"><img src="./logos/p-logo.png" alt="Paradoxal" /></div>
        </Nav.Link>
      </div>

      </Fade>

    </div>
    )
  }
}

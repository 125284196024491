import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Container } from 'react-bootstrap'

import ScrollToTop from './components/scroll-to-top';
import Paradoxal from './pages/paradoxal'
import "./sass/style.scss";


const routes = [
  { path: '/', name: 'Paradoxal', Component: Paradoxal },
]

function App() {

  return (
    <Router basename='/'>
      <>
        <ScrollToTop>
          <Container className="container" id="container">
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path} exact>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={600}
                    classNames="page"
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
            <Redirect from='*' to='/' />

          </Container>
        </ScrollToTop>
      </>

    </Router>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)

import React, { Component } from 'react';
import Axios from "axios";
import Fade from 'react-reveal/Fade';
import Video from '../components-paradoxal/video';
import Image from 'react-shimmer'
import { CSSTransition } from 'react-transition-group'
import Loader from '../components/loader'

export default class Content extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [],
      muted: "muted",
      category: "none",
      page: 1,
      total: 0,
      lastPage: 2,
      open: false,
      previous: "inactive",
      next: "active",
      videoPlaying: false,
      slug: "",
      start: 0,
      end: 4,
      loading: true

    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.updateData = this.updateData.bind(this);
    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);


  }

  componentDidMount() {
    this.updateData()
    console.log(this.props.category)
  }

  updateData(prevState){
    let cat = this.props.category
    if (cat.length > 0) {
      Axios.get(`http://admin.residencycontent.com/index.php/wp-json/wp/v2/paradoxal?filter[taxonomy]=category&filter[term]=${cat}&status=publish`).then(video => {
        let total = video.data.length
        let lastPage = Math.ceil(total / 4)
        this.setState({
          content: video.data,
          total: total,
          lastPage: lastPage,
          loading: false
        });
        console.log(cat)
        if (total > 4 && this.state.page == 1){
          this.setState({
            next: "active",
          });
        }
        else {
          this.setState({
            next: "inactive",
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const category = this.props.category;
    const page = this.state.page;
    if (category !== prevProps.category) {
      this.setState({
      category: category,
      page: 1,
      start: 0,
      end: 4,
      previous: "inactive",
      loading: true
      },
      () => {
        this.updateData();
      })
    }
    // if (page !== prevState.page) {
    //   this.updateData();
    // }
  }

  nextPage = () => {
    let newPage = this.state.page + 1
    let newStart = this.state.start + 4
    let newEnd = this.state.end + 4
    if (this.state.page < this.state.lastPage){
      this.setState({
        page: newPage,
        start: newStart,
        end: newEnd,
        previous: "active"
      });
    }
    if (newPage == this.state.lastPage){
      this.setState({
        next: "inactive"
      });
    }
  }

  previousPage = () => {
    let newPage = this.state.page - 1
    let newStart = this.state.start - 4
    let newEnd = this.state.end - 4
    if (this.state.page > 1){
      this.setState({
        page: newPage,
        start: newStart,
        end: newEnd,
        next: "active"
      });
    }
    if (newPage == 1){
      this.setState({
        previous: "inactive"
      });
    }
  }

  openVideo(slug) {
    this.setState({
      videoPlaying: true,
      slug: slug
    });
    document.body.classList.add('video-open');
  }

  closeVideo() {
    this.setState({
      videoPlaying: false,
    });
    document.body.classList.remove('video-open');
  }




  render() {
    const { content, loading, start, end, videoPlaying, slug } = this.state
    let projects = content.length > 0
    ? content.slice(start, end).map((content, index) => {
        let slug = content ? content.slug : null
        let video = content && content.acf ? content.acf.video : null
        let director = content && content.acf ? content.acf.director : null
        let title = content && content.title ? content.title.rendered : null
        let client = content && content.acf ? content.acf.client : null
        let agency = content && content.acf ? content.acf.agency : null
        let location = content && content.acf ? content.acf.location : null
        let production_studio = content && content.acf ? content.acf.studio : null

        let image_compressed = content && content.acf && content.acf.thumbnail_image && content.acf.thumbnail_image.sizes ? content.acf.thumbnail_image.sizes.medium : null
        let image_full = content && content.acf && content.acf.thumbnail_image && content.acf.thumbnail_image.sizes ? content.acf.thumbnail_image.sizes.full : null



        const category = this.props.category;
        let delay = index * 50

        return (
          <Fade key={slug} delay={delay}>
            <div className={`project col-3 project-${category}`}>
              <div className="thumbnail-wrapper ratio-16-9" onClick={this.openVideo.bind(this, slug)} >
                <Fade delay={delay}>
                  <Image
                    src={image_compressed ? image_compressed : image_full}
                    width={640} height={480}
                    className="background-image"
                  />
                </Fade>
              </div>

              <div className="project-info">
                <h5>{director}</h5>
                <h6>{title}</h6>
              </div>

              <div className="project-hover">
                <h6>Director: <span>{director}</span></h6>
                <h6>Production: <span>{production_studio}</span></h6>
                <h6>Client: <span>{client}</span></h6>
                <h6>Agency: <span>{agency}</span></h6>
                <h6>Location: <span>{location}</span></h6>
              </div>

            </div>
          </Fade>
        )
      })
    : []
    return (
      <Fade opposite>
        <div className={'projects-outer-wrapper'}>

          {
            videoPlaying &&
            <CSSTransition
              in={videoPlaying}
              timeout={600}
              classNames="video-player"
              unmountOnExit
            >
              <div onClick={this.closeVideo}><Video slug={this.state.slug}/></div>
            </CSSTransition>
          }

          <div className="projects-wrapper">
            {
              loading ? <Loader/> : projects
            }
          </div>

          <div className="pagination">
            <div className={"pagination-button previous " + this.state.previous} onClick={this.previousPage}><img src="./graphics/arrow.svg" alt="previous" /></div>
            <div className={"pagination-button next " + this.state.next} onClick={this.nextPage}><img src="./graphics/arrow.svg" alt="next" /></div>
          </div>

        </div>
      </Fade>
      )
    }
}

import React, { Component } from 'react';
import Footer from '../components-paradoxal/footer';
import Projects from '../components-paradoxal/projects';
import Navigation from '../components-paradoxal/navigation';
import Axios from "axios";
import Masonry from 'react-masonry-component';
import Fade from 'react-reveal/Fade';
import Image from 'react-shimmer'
import Loader from '../components/loader';
import { CSSTransition } from 'react-transition-group'

export default class Paradoxal extends Component {
  render() {
    return (
      <div className="page-wrapper paradoxal" id="body">
      <Navigation />
      <Content />
      <Footer />
      </div>
    );
  }
}

class Content extends Component {
  constructor() {
    super();
    this.state = {
      content: [],
      state: "category-closed",
      active: null,
    }
    this.openCategory = this.openCategory.bind(this);
    this.closeCategory = this.closeCategory.bind(this);
  }

  componentDidMount() {
    Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/categories?_embed=1").then(post => {
      this.setState({
        content: post.data
      });
    });
    document.body.classList.add('paradoxal');
  }

  componentWillUnmount() {
    document.body.classList.remove('paradoxal');
  }

  openCategory(slug) {
    this.setState({
      state: "category-open",
      active: slug
    });
    this.forceUpdate()
  }

  closeCategory() {
    this.setState({
      state: "category-closed",
      active: null
    });
  }

  render() {
    const { content } = this.state
    let categories = content.length > 0
      ? content.map( (category, index) => {
          let name = category ? category.name : null
          let slug = category ? category.slug : null
          let background = category && category.acf && category.acf.background_video ? category.acf.background_video.url : null
          let delay = index * 50
          return (
            <Fade key={slug} delay={delay}>
              <h2 onClick={this.openCategory.bind(this, slug)} className={name + ' ' + (slug === this.state.active ? " active" : "")} key={index} style={{ backgroundImage: `url(${background})` }}>
                {name}
              </h2>
            </Fade>
          )
        })
      : []

    return (
      <div className="page-body paradoxal">
        <div className={'category-content content ' + this.state.state}>
          <div className="category-content-inner">
            <div className='row categories initial row-title work'>
              <div className="col-1">
                <h6>Work</h6>
              </div>
              {
                this.state.active === null &&
                <div className="col-5 category-list">
                  {
                    content.length > 0
                    ? categories
                    : <Loader/>
                  }
                </div>
              }
              <img src="./graphics/close-24px.svg" alt="close" className="close" onClick={this.closeCategory} />
            </div>
            {
              this.state.active != null &&
              <div className='row projects-list-wrapper'>
                <div className="col-2 categories-submenu col-2">
                  {categories}
                </div>
                <div className="col-4 projects-list">
                  <Fade>
                    <Projects category={this.state.active} />
                  </Fade>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="content locations-header">
          <div className="row row-title locations">
            <div className="col-1">
              <Fade>
                <h6>Locations</h6>
              </Fade>
            </div>
          </div>
        </div>
        <Locations />
      </div>
    );
  }
}

const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

class Locations extends React.Component {
  constructor() {
    super();
    this.state = {
      content: [],
    }
  }
  async componentDidMount() {
   await Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/locations?_embed=1&status=publish&per_page=7")
   .then(post => {
     this.setState({
       content: post.data
     });
   });
 }
  render() {
    const { content } = this.state
    return (
      <div>
        {
          content.length > 0 &&
          <Masonry
              className={'locations-wrapper'} // default ''
              elementType={'div'} // default 'div'
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              imagesLoadedOptions={imagesLoadedOptions} // default {}
          >
            {
              content.slice(0, 7).map((location, index) => {
                let name = location && location.title ? location.title.rendered : null
                let theLocation = location && location.acf ? location.acf.location : null
                let image_full= location && location._embedded && location._embedded['wp:featuredmedia'] && location._embedded['wp:featuredmedia']['0'] && location._embedded['wp:featuredmedia']['0'].media_details && location._embedded['wp:featuredmedia']['0'].media_details.sizes && location._embedded['wp:featuredmedia']['0'].media_details.sizes.full ? location._embedded['wp:featuredmedia']['0'].media_details.sizes.full.source_url : null
                let image_compressed = location && location._embedded && location._embedded['wp:featuredmedia'] && location._embedded['wp:featuredmedia']['0'] && location._embedded['wp:featuredmedia']['0'].media_details && location._embedded['wp:featuredmedia']['0'].media_details.sizes && location._embedded['wp:featuredmedia']['0'].media_details.sizes.large ? location._embedded['wp:featuredmedia']['0'].media_details.sizes.large.source_url : null
                let hover_compressed = location && location.acf && location.acf.hover_image && location.acf.hover_image.sizes ? location.acf.hover_image.sizes.large : null
                let hover_full = location && location.acf && location.acf.hover_image && location.acf.hover_image.sizes ? location.acf.hover_image.sizes.full : null
                let delay = index * 50
                return (
                  <Fade key={`location-${index}`} delay={delay}>
                    <div className="location">

                        <div>
                          <Image
                            src={image_compressed ? image_compressed : image_full}
                            width={1200} height={1200}
                            className="background-image"
                          />
                          <Image
                            src={hover_compressed ? hover_compressed : hover_full}
                            width={1200} height={1200}
                            className="hover-image"
                            style={{ display: 'none' }}
                          />
                        </div>


                        <div className="overlay">
                          <div className="location-info">
                            <h2 className="outline-white">{name}</h2>
                            <h4>{theLocation}</h4>
                          </div>
                        </div>
                    </div>
                  </Fade>
                )
              })
            }
          </Masonry>
        }
      </div>
    )
  }
}

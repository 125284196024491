import React from 'react';
import { Nav } from 'react-bootstrap'
import { BrowserRouter as Link, NavLink } from 'react-router-dom'


    export default function Navigation() {

        return (
          <div className="navigation" id="main-navigation">
            <div className="content">
              <div className="row">
                <div className="col-6">
                <div className="menu-outer-wrapper">

                  <div className="logo-wrapper">
                    <Nav.Link
                      key="/"
                      as={NavLink}
                      to="/"
                      activeClassName="active"
                      exact
                      className="paradoxal"
                    >
                    <img src="./logos/paradoxal.gif" alt="Paradoxal" />
                    </Nav.Link>
                  </div>
                  <a href="http://residencycontent.com/" target="_blank" className="residency">
                    Residency
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        );
    }
